import ImageGallery from "react-image-gallery";
import "../gallery/Gallery.css";
import images from "../../pics/wild-west/wild-west.js";
import { shuffleArray } from '../../utils.js';

export default function WildWest() {
  return (
    <div className="bg-stone-200 flex justify-center">
      <ImageGallery
        items={shuffleArray(images)}
        showThumbnails={true}
        showFullscreenButton={true}
        showPlayButton={true}
      />
    </div>
  );
}
