import ImageGallery from 'react-image-gallery';
import images from "../../pics/waterofalps/waterofalps";
import "../gallery/Gallery.css";
import { shuffleArray } from '../../utils.js';

  export default function WatersOfAlps () {

    return (
      <div className='bg-stone-200 flex justify-center'>
        <ImageGallery items={shuffleArray(images)} 
        showThumbnails={true} 
        showFullscreenButton={true} 
        showPlayButton={true} />
      </div>
    );
    
  };